<template>
  <div class="undergraduate">
    <!-- <el-image
        :src="src"
        style="height: 500px; width: 100vw"
        fit="cover"
    ></el-image> -->
    <div>
      <template v-if="language=='zh'">
        <myimage :url='url'></myimage>
      </template>
      <template v-if="language=='TW'">
        <myimage :url='urlTW'></myimage>
      </template>
      <template v-if="language=='En'">
        <myimage :url='urlEn'></myimage>
      </template>
    </div>
    <div class="content2">
      <div class="title">
        <div class="english-yellow">{{$t('associatedegree.Advantages')}} </div>
        <div class="service-con">
          <div class="servicetop">{{$t('associatedegree.留学优势')}}</div>
          <div class="line2"></div>
        </div>
      </div>
      <div class="tabs">
        <div
            :class="tabsindex == index ? 'change' : ''"
            v-for="(item, index) in Associate"
            :key="index"
            @click="tabsclick(index,item.abroadId)"
        >{{item.subTitle}}
<!--          <template v-if="item.title.length<8">{{ item.title }}</template>-->
<!--          <template v-else>{{ item.title.slice(0,6) }}...</template>-->
        </div>
      </div>
      <div class="youshi">
        <div class="youshi-img">
          <el-image :src="baseUrl+AbroadDetils.image" fit="cover"></el-image>
        </div>
        <div style="width: calc(100% - 500PX)!important">
          <div class="title2">{{AbroadDetils.title}}</div>
          <div class="text">
            {{AbroadDetils.content}}
          </div>
          <div class="btn" @click="contactus">{{$t('associatedegree.联系我们')}}</div>
        </div>
      </div>
    </div>
    <div class="bgc backf7">
      <div class="content3">
        <div class="title">
          <div class="english-yellow">{{$t('associatedegree.Study')}}</div>
          <div class="service-con">
            <div class="servicetop2">{{$t('associatedegree.选择适合你的留学计划')}}</div>
          </div>
        </div>
        <div>
         <template v-if="language=='zh'">
           <img src="../../assets/lxue.png" style="width: 100%" @click="toapply">
         </template>
          <template v-if="language=='TW'">
            <img src="../../assets/lxueTW.png" style="width: 100%" @click="toapply">
          </template>
          <template v-if="language=='En'">
            <img src="../../assets/lxueEn.png" style="width: 100%" @click="toapply">
          </template>
        </div>
<!--        <div class="list">-->
<!--          <div class="card" v-for="(item, index) in list2" :key="index">-->
<!--            <div :style="{ 'background-color': item.color }" >-->
<!--              {{ item.title }}-->
<!--            </div>-->
<!--            <template v-if="index==0">-->
<!--              <div class="div">-->
<!--              <div class="text">-->
<!--                <img src="../../assets/image/548.png" style="" alt=""/>-->
<!--                <span>{{$t('associatedegree.适合人群')}}：</span>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('associatedegree.没有留学申请经验')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('associatedegree.没有确定申请学校')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('associatedegree.没有确定申请学校')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('associatedegree.英语语言能力不足')}}</div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="div">-->
<!--              <div class="text" style="background-color: #fff">-->
<!--                <img src="../../assets/image/967.png" style="" alt=""/>-->
<!--                <span>{{$t('associatedegree.项目特色')}}：</span>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('associatedegree.没有留学申请经验')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('associatedegree.没有确定申请学校')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('associatedegree.没有确定申请学校')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('associatedegree.英语语言能力不足')}}</div>-->
<!--              </div>-->
<!--            </div>-->
<!--            </template>-->
<!--            <template v-if="index==1">-->
<!--               <div class="div">-->
<!--              <div class="text">-->
<!--                <img src="../../assets/image/548.png" style="" alt=""/>-->
<!--                <span>{{$t('associatedegree.适合人群')}}：</span>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('associatedegree.想要提升背景')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('associatedegree.想要有专业老师把控整个申请流程')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('associatedegree.想有导师全程规划考试及选课流程')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('associatedegree.除求学外')}}</div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="div">-->
<!--              <div class="text" style="background-color: #fff">-->
<!--                <img src="../../assets/image/967.png" style="" alt=""/>-->
<!--                <span>{{$t('associatedegree.项目特色')}}：</span>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('associatedegree.留学工作全指导')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('associatedegree.没量身打造独特背景提升增加竞争优势')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('associatedegree.中美两国咨询老师全程把关助力申请')}}</div>-->
<!--              </div>-->
<!--              <div style="height: 16px">-->
<!--              </div>-->
<!--            </div>-->
<!--            </template>-->
<!--            -->
<!--            <template v-if="index==2">-->
<!--               <div class="div">-->
<!--              <div class="text">-->
<!--                <img src="../../assets/image/548.png" style="" alt=""/>-->
<!--                <span>{{$t('associatedegree.适合人群')}}：</span>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('associatedegree.已完成标准化考试')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('associatedegree.初步确定目标学校')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('associatedegree.无法独立完成文书')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('associatedegree.希望获得专业指导')}}</div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="div">-->
<!--              <div class="text" style="background-color: #fff">-->
<!--                <img src="../../assets/image/967.png" style="" alt=""/>-->
<!--                <span>{{$t('associatedegree.项目特色')}}：</span>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('associatedegree.专业咨询老师评价学生背景')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('associatedegree.专业文书老师指导创作思路')}}</div>-->
<!--              </div>-->
<!--              <div class="text2">-->
<!--                <div></div>-->
<!--                <div>{{$t('associatedegree.可灵活选择签注指导')}}</div>-->
<!--              </div>-->
<!--              <div style="height: 16px">-->

<!--              </div>-->
<!--            </div>-->
<!--            </template>-->
<!--            <div class="btn" :style="{ 'background-color': item.color }"   @click="toapply">-->
<!--              {{ item.btn }}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
    <div class="content2">
      <div class="title">
        <div class="english-yellow">{{$t('associatedegree.SERVICESYSTEM')}}</div>
        <div class="service-con">
          <div class="servicetop">
            {{$t('associatedegree.科学智能的服务体系')}}
          </div>
          <div class="line2"></div>
        </div>
      </div>
      <div class="fuwu">
        <div :class="language=='En'?'card3':'card3 heightcard3'">
          <div>{{$t('associatedegree.详细的留学规划')}}</div>
          <div class="underline"></div>
          <div>
            {{$t('associatedegree.基于企业内部庞大的数据库资料')}}
          </div>
        </div>
        <div :class="language=='En'?'card3':'card3 heightcard3'">
          <div>{{$t('associatedegree.全方位的背景提升')}}</div>
          <div class="underline"></div>
          <div>
            {{$t('associatedegree.根据选校定位为学生匹配相关的背景提升项目和机会')}}
          </div>
        </div>
        <div :class="language=='En'?'card3':'card3 heightcard3'">
          <div>{{$t('associatedegree.优质的文书材料')}}</div>
          <div class="underline"></div>
          <div>
            {{$t('associatedegree.文书顾问与学生进行头脑风暴')}}
          </div>
        </div>
        <div :class="language=='En'?'card3':'card3 heightcard3'">
          <div>{{$t('associatedegree.高效的笔试面试特训')}}</div>
          <div class="underline"></div>
          <div>
            {{$t('associatedegree.HK汇生会拥有丰富的本科')}}
          </div>
        </div>
        <div :class="language=='En'?'card3':'card3 heightcard3'">
          <div> {{$t('associatedegree.升学后的留学援助')}}</div>
          <div class="underline"></div>
          <div>
            {{$t('associatedegree.HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题')}}
          </div>
        </div>
      </div>
    </div>
    <div class="bgc backAF6">
      <div class="content3">
        <div class="title">
          <div class="english-yellow">{{$t('associatedegree.AssociateDegreeProgressionPathway')}}</div>
          <div class="service-con">
            <div class="servicetop2">{{$t('associatedegree.副学士升学途径')}}</div>
            <div class="line2"></div>
          </div>
        </div>
<!--        <div class="servicesystem">-->
<!--          <img src="../../assets/image/servicesystem.png" alt="">-->
<!--        </div>-->
        <div class="servicesystem">
          <template v-if="language=='zh'">
            <img src="../../assets/fxs.png" alt="">
          </template>
          <template v-if="language=='TW'">
            <img src="../../assets/fxsTW.png" alt="">
          </template>
          <template v-if="language=='En'">
            <img src="../../assets/fxsEn.png" alt="" style="height: 343PX">
          </template>
        </div>
      </div>
    </div>
    <div class="content2">
      <div class="title">
        <div class="english-yellow">{{$t('associatedegree.Introduction')}}</div>
        <div class="service-con">
          <div class="servicetop">
            {{$t('associatedegree.副学位课程介绍')}}
          </div>
          <div class="line2"></div>
        </div>
      </div>
      <div class="study-fxw">
       <template v-if="language=='zh'">
         <img src="../../assets/image/fxw.png" alt="">
       </template>
        <template v-if="language=='TW'">
          <img src="../../assets/image/fxwTW.png" alt="">
        </template>
        <template v-if="language=='En'">
          <img src="../../assets/image/fxwEn.png" alt="" >
        </template>
      </div>
    </div>
    <div class="content2">
      <div class="title">
        <div class="english-yellow">{{$t('associatedegree.Application')}}</div>
        <div class="service-con">
          <div class="servicetop">
           {{$t('associatedegree.副学士申请条件')}} 
          </div>
          <div class="line2"></div>
        </div>
      </div>
      <div class="study-apply">
        <div>
          <div class="study-applytop"> {{$t('associatedegree.内地高考成绩')}}</div>
          <div class="study-applybot">
            <div>
               {{$t('associatedegree.内地高考成绩')}}
            </div>
            <div> {{$t('associatedegree.达二本线或以上')}}</div>
          </div>
        </div>
        <div>
          <div class="study-applytop"> {{$t('associatedegree.国际生成绩')}}</div>
          <div class="study-applybot">
            <div>
               {{$t('associatedegree.HKDSE')}}\{{$t('associatedegree.SAT')}}\{{$t('associatedegree.LEVEL')}}\{{$t('associatedegree.IB')}}<br/> {{$t('associatedegree.考试成绩')}}

            </div>
            <div> {{$t('associatedegree.BETC')}}<br/> {{$t('associatedegree.正规国际高中成绩单')}}</div>
          </div>
        </div>
        <div>
          <div class="study-applytop"> {{$t('associatedegree.面试成绩')}}</div>
          <div class="study-applybot">
            <div>
               {{$t('associatedegree.高考英语单科成绩达90分')}}
            </div>
            <div>  {{$t('associatedegree.满分150分制')}}</div>
          </div>
        </div>
        <div class="study-applysmallbot">
          <div class="study-applysmallbotone">
            <div> {{$t('associatedegree.部分专业需要')}}</div>
            <div> {{$t('associatedegree.具体分数要求')}}</div>
          </div>
          <div class="study-applysmallbottwo" @click="toapply"> {{$t('associatedegree.点击咨询')}}</div>
        </div>
      </div>
    </div>
    <div class="bgc backAF6">
      <div class="content3">
        <div class="title">
          <div class="english-yellow">{{$t('associatedegree.Applications')}}</div>
          <div class="service-con">
            <div class="servicetop2">{{$t('associatedegree.副学士申请时间流程')}}</div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="apply">
         <template v-if="language=='zh'">
           <img src="../../assets/image/apply.png" alt="">
         </template>
          <template v-if="language=='TW'">
            <img src="../../assets/image/applyTW.png" alt="">
          </template>
          <template v-if="language=='En'">
            <img src="../../assets/image/applyEn.png" alt="">
          </template>
        </div>
      </div>
    </div>
    <div class="content2">
      <div class="title">
        <div class="english-yellow">{{$t('associatedegree.Associate')}}</div>
        <div class="service-con">
          <div class="servicetop">
           {{$t('associatedegree.副学士录取案例')}}
          </div>
          <div class="line2"></div>
        </div>
      </div>
      <div class="successoffer">
        <div v-for="(item,index) in Admission" :key='index'>
          <div class="successoffer-left">
             <img :src="baseUrl+item.image" alt="">
          </div>
          <div class="successoffer-right">
            <div> {{$t('associatedegree.学生姓名')}}：{{item.studentName}}</div>
            <div> {{$t('associatedegree.申请类型')}}：{{item.applyType}}</div>
            <div> {{$t('associatedegree.学术成绩')}}：{{item.academicRecord}}</div>
            <div> {{$t('associatedegree.英语成绩')}}：{{item.englishResults}}</div>
            <div> {{$t('associatedegree.录取大学')}}：{{item.admissionSchool}}</div>
          </div>
        </div>
      </div>
    </div>
    <mydialog ref="dialog"></mydialog>
    <erweimaDialog ref="ewmdialog"></erweimaDialog>
  </div>
</template>

<script>
import mydialog from '@/components/dialog'
import erweimaDialog from '@/components/erweimaDialog'
import myimage from '@/components/myimage'
import {hongKongStudyAbroadList,hongKongStudyAbroadDetils} from '@/api/index.js'
export default {
  components:{
    myimage,
    mydialog,
    erweimaDialog
  },
  data() {
    return {
      src: "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
      url:require('../../assets/phone/tu1.png'),
      urlTW:require('../../assets/phone/tu1TW.png'),
      urlEn:require('../../assets/phone/tu1En.png'),
      list: ["优势一", "优势二", "优势三", "优势四", "优势五"],
      tabsindex: 0,
      Associate:[],
      AbroadDetils:{},
      baseUrl:'',
      Admission:[],//录取捷报列表
      language:'zh'
    };
  },
  computed:{
    list2(){
      return [
        {
          title: this.$t('overseasStudy.全套申请计划'),
          btn: this.$t('overseasStudy.留学咨询'),
          color: "#4486e6",
        },
        {
          title: this.$t('overseasStudy.直通车申请计划'),
          btn: this.$t('overseasStudy.留学咨询'),
          color: "#F78A13",
        },
        {
          title:this.$t('overseasStudy.半DIY申请计划') ,
          btn: this.$t('overseasStudy.留学咨询'),
          color: "#68D26E",
        },
      ]
    } 
  },
  mounted() {
    console.log(this.list2[0].title);
    this._hongKongStudyAbroadList(1,2)
    this._hongKongStudyAbroadList(4,5)
    this.baseUrl=this.$store.state.baseUrl
    this.language=localStorage.getItem('language')
  },
  methods: {
    _hongKongStudyAbroadList(category,type){
      hongKongStudyAbroadList({
        category:category,
        type:type,
      }).then(res=>{
        console.log(res)
        if(category==1){
          this.Associate=res.data
          this._hongKongStudyAbroadDetils(res.data[0].abroadId)
        }
         if(category==4){
          this.Admission=res.data.slice(0,4)
          this.Admission = this.Admission.reverse()
        }
      })
    },
    toapply(){
      this.$refs.ewmdialog.dialogVisibletwo=true
    },
     tabsclick(index,abroadId) {
      this.tabsindex = index;
      this._hongKongStudyAbroadDetils(abroadId)
      
    },
    _hongKongStudyAbroadDetils(abroadId){
      hongKongStudyAbroadDetils({
        abroadId:abroadId
      }).then(res=>{
        this.AbroadDetils=res.data
      })
    },
    contactus(){
      this.$refs.ewmdialog.dialogVisibletwo= true;
    },
  },
};
</script>

<style lang="scss">
.undergraduate {
  .backf7 {
    background-color: #f7f7f7;
  }

  .backAF6 {
    background-color: #FEFAF6;
    padding-bottom: 50PX;
  }

  .bgc {
    width: 100%;

    .content3 {
      width: 1176px;
      margin: 0 auto;
      padding-top: 1px;

      .apply {
        img {
          width: 1176px;
          height: 624px;
        }
      }

      .servicesystem {
        img {
          width: 1176px;
          height: 430px;
        }
      }

      .list {
        display: flex;
        justify-content: space-around;
        padding-bottom: 70px;

        .card {
          width: 370px;
          height: 595px;
          border-radius: 20px;
          background-color: #fff;

          .btn {
            width: 312px;
            height: 48px;
            // background-color: #4486e6;
            border-radius: 12px;
            color: #fff;
            font-size: 20px;
            line-height: 48px;
            text-align: center;
            margin: 0 auto;
            margin-top: 50px;
          }

          > .div {
            text-align: left;
            padding-left: 24px;
            padding-right: 24px;
            margin-top: 20px;

            .text2 {
              display: flex;
              align-items: center;
              font-size: 15px;
              margin-bottom: 10px;

              > div:nth-child(1) {
                background-color: #333;
                width: 6px;
                height: 6px;
                border-radius: 6px;
                margin-right: 8px;
              }
            }

            .text {
              margin-bottom: 17px;

              > img {
                height: 28px;
                width: 28px;
                vertical-align: middle;
                margin-right: 20px;
                margin-top: -5px;
              }

              > span {
                font-size: 22px;
              }
            }
          }

          > div:nth-child(1) {
            height: 88px;
            border-radius: 20px 20px 0 0;
            // background-color: #4486e6;
            line-height: 88px;
            font-size: 24px;
            color: #fff;
            text-align: center;
            font-weight: 600;
          }
        }
      }

      .title {
        position: relative;
        height: 150px;
        margin-top: 50px;

        .english-yellow {
          color: #fe992b;
          font-size: 44px;
          opacity: 0.3;
        }

        .service-con {
          width: 100%;
          position: absolute;
          top: 17px;
        }

        .line2 {
          width: 124px;
          height: 6px;
          border-radius: 3px;
          background-color: #fe992b;
          margin: 0 auto;
        }

        .servicetop2 {
          // width: 320px;
          font-size: 32px;
          color: #333;
          font-weight: bold;
          padding: 10px 0;
          // border-bottom: 6px solid #fe992b;
          border-radius: 3px;
          margin: 0 auto;
        }
      }
    }
  }

  .content2 {
    width: 1176px;
    margin: 0 auto;
    padding-bottom: 50PX;

    .fuwu {
      display: flex;
      justify-content: space-around;
      .heightcard3{
        height: 354px;
      }
      .card3 {
        width: 230px;
        //height: 354px;
        border-radius: 12px;
        background-color: #FEF8E9;
        color: rgb(0, 0, 0);
        padding: 50px 27px 0px 27px;
        text-align: left;
        transition: all 0.5s;
        position: relative;

        > div:nth-child(1) {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 24px;
          text-align: center;
          position: relative;
          z-index: 1000 !important;
        }

        > div:nth-child(3) {
          font-size: 16px;
          line-height: 28px;
        }

        .underline {
          width: 50%;
          height: 10PX;
          background: linear-gradient(180deg, #FE992B 0%, rgba(254, 153, 43, 0.3) 100%);
          border-radius: 5PX;
          position: absolute;
          top: 65px;
          text-align: center;
          z-index: 1;
          left: calc(50% - 25%);
        }
      }

      .card3:hover {
        background-color: #FE992B;
        color: rgb(255, 255, 255);
      }
    }

    .youshi {
      display: flex;
      text-align: left;
      margin-bottom: 66px;

      .youshi-img {
        margin-right: 36px;
          width: 456PX;
          height: 380PX;

        > img {
          width: 456PX;
          height: 352PX;
        }
      }

      div:nth-child(2) {
        position: relative;

        .btn {
          //position: absolute;
          width: 234px;
          height: 70px;
          border-radius: 35px;
          line-height: 70px;
          text-align: center;
          background-color: #fe992b;
          color: #fff;
          font-size: 20px;
          font-weight: 600;
          margin-top: 20PX;
          //bottom: 5px;
        }

        .text {
          font-size: 18px;
          line-height: 38px;
          // margin-bottom: 40px;
        }

        .title2 {
          color: #333;
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 20px;
        }
      }
    }

    .tabs {
      border-bottom: 4px solid rgba($color: #fe992b, $alpha: 0.3);
      display: flex;
      justify-content: center;
      margin-bottom: 36px;

      > div {
        //width: 160px;
        padding: 0 5px;
        height: 60px;
        border-radius: 8px 8px 0px 0px;
        line-height: 60px;
        text-align: center;
        color: #333;
        font-size: 20px;
        // transition: all 0.5s;
      }

      .change {
        background-color: #fe992b;
        color: #fff;
        transition: all 0.5s;
      }
    }

    .study-fxw {
      width: 100%;
      background: #fff;
      height: 420px;

      img {
        width: 100%;
        height: 420px;
      }
    }

    .study-abroad {
      width: 100%;
      background: #fff;
      height: 238px;

      img {
        width: 100%;
        height: 238px;
      }
    }

    .study-apply {
      width: 100%;
      height: 380px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > div {
        width: 270px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .study-applytop {
          width: 270px;
          height: 110px;
          line-height: 110px;
          background: #FE992B;
          border-radius: 12px;
          color: #fff;
          font-weight: bold;
          font-size: 24px;
        }

        .study-applybot {
          width: 270px;
          height: 240px;
          border-radius: 12px;
          box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 18px;

          > div {
            line-height: 30px;
          }

        }

      }

      .study-applysmallbot {
        background: #FE992B !important;

        .study-applysmallbotone {
          padding-top: 113px ;
          font-size: 24px;
          color: #fff;
        }

        .study-applysmallbottwo {
          border-radius: 24px;
          font-size: 20px;
          color: #FE992B;
          height: 48px;
          line-height: 48px;
          width: 168px !important;
          background: #fff;
          margin-bottom: 40px;
        }
      }
    }

    .successoffer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      > div {
        width: 574px;
        height: 360px;
        display: flex;
        flex-direction: row;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
        margin-bottom: 28px;
        padding: 37px 30px;

        .successoffer-left {
          width: 216px;
          height: 286px;
          background: #F7F7F7;
          margin-right: 24px;
        }
        .successoffer-left img{
          width: 216px;
          height: 286px;
        }
        .successoffer-right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: left;
          font-size: 24px;
          font-weight: 400;
          color: #333333;
        }
      }
    }

    .title {
      position: relative;
      height: 150px;
      margin-top: 50px;

      .english-yellow {
        color: #fe992b;
        font-size: 44px;
        opacity: 0.3;
      }

      .service-con {
        width: 100%;
        position: absolute;
        top: 17px;
      }

      .line2 {
        width: 124px;
        height: 6px;
        border-radius: 3px;
        background-color: #fe992b;
        margin: 0 auto;
      }

      .servicetop {
        // width: 160px;
        font-size: 32px;
        color: #333;
        font-weight: bold;
        padding: 10px 0;
        // border-bottom: 6px solid #fe992b;
        border-radius: 3px;
        margin: 0 auto;
      }

      .servicetop2 {
        width: 320px;
        font-size: 32px;
        color: #333;
        font-weight: bold;
        padding: 10px 0;
        border-bottom: 6px solid #fe992b;
        border-radius: 3px;
        margin: 0 auto;
      }
    }
  }
}
</style>